import React, { Component } from "react";

class GetStarted extends Component {
  render() {
    return (
      <section>
        <div className="container">
          <h1 className="title">Let's get started.</h1>
        </div>
      </section>
    );
  }
}

export default GetStarted;
